<template>
  <v-card class="accounting-document d-flex flex-column non-printable" style="height: 100%;">
    <v-toolbar color="grey darken-3" dark elevation="1" style="flex: 0" height="52">
      <v-toolbar-title class="document-toolbar-title">
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'} ${is_legacy ? '[Юр.облік]' : ''}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-dialog v-model="show_tax_dialog" persistent width="500">
      <v-card>
        <v-card-text class="pa-0 grey lighten-3">
          <div class="py-3 px-6 text-center black--text" style="font-size: 1rem">
            Налаштування обліку ПДВ
          </div>
        </v-card-text>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" class="py-1">
              <v-switch class="mt-0" color="success" hide-details
                        :label="tax_enable_local ? 'Враховувати ПДВ' : 'Не враховувати ПДВ'"
                        v-model="tax_enable_local"/>
            </v-col>
            <v-col cols="12" class="py-1">
              <v-switch class="mt-0" color="success" hide-details
                        :label="tax_in_sum_local ? 'ПДВ у тому числі' : 'ПДВ зверху'"
                        v-model="tax_in_sum_local"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small depressed color="secondary" @click="closeTaxModal(true)">ОК</v-btn>
          <v-btn small depressed @click="closeTaxModal(false)">Закрити</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-text class="pt-2 pb-0"
                 style="flex: 1; display: flex; flex-direction: column; overflow: hidden;"
    >
      <div class="conducted-box d-flex">
        <v-alert
            dense
            text
            class="mb-0 py-1"
            :color="conducted ? 'success' : 'secondary'"
            :type="conducted ? 'success' : 'warning'"
            style="flex: 1"
        >
          {{ conducted ? 'Документ проведений' : 'Документ не проведений' }}
        </v-alert>
      </div>
      <div class="document-header" style="flex: 0">
        <div style="display: flex; flex-wrap: nowrap;">
          <div>
            <div class="document-header-row px-0 pt-2 pb-2">
              <div style="flex: 0 0 170px; margin-right: 16px">
                <date_input v-model="date" label="Дата: " :time="true" required :disabled="false"
                            @userChange="onDateChange" id="date"/>
              </div>
              <div style="flex: 0 0 140px; margin-right: 16px">
                <string_input v-model="number" label="Номер: " :disabled="true"/>
              </div>
              <div style="flex: 0 0 100px; margin-right: 16px">
                <select_input
                    v-model="currency_id"
                    :computed-action="'SELECT_CURRENCY_LIST_ELEMENTS'"
                    :computed-getter="'get_currency_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    label="Валюта: "
                    :select-button-object="{
                                name: 'CURRENCY_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список валют',
                                element_title: 'Валюта',
                                show: true
                              }"
                    @selectChange="onDateChange"
                />
              </div>
              <div style="flex: 0 0 140px; margin-right: 16px; align-self: flex-end">
                <v-btn block small depressed height="31" @click="show_tax_dialog = true">Ціни та ПДВ</v-btn>
              </div>
            </div>
            <div class="document-header-row px-0 pt-1 pb-2">
              <div style="flex: 0 0 326px; margin-right: 16px">
                <select_input
                    v-model="contractor_id"
                    :computed-action="'SELECT_CONTRACTOR_LIST_ELEMENTS'"
                    :computed-getter="'get_contractor_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    :action-props="{currency_id: currency_id, date: date}"
                    :show-additional-field="true"
                    label="Контрагент"
                    :show-select-on-focus="false"
                    :select-button-object="{
                                name: 'CONTRACTOR_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список контрагентів',
                                element_title: 'Контрагент',
                                show: true,
                                action_props: {currency_id: currency_id, date: date},
                                showAdditionalField: true
                              }"
                    @selectChange="get_contractor_balance"
                />
              </div>
              <div style="flex: 0 0 256px; margin-right: 16px">
                <select_input
                    v-model="contract_id"
                    :computed-action="'SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS'"
                    :computed-getter="'get_contractor_contract_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    :owner_id="contractor_id"
                    label="Договір"
                    :action-props="{owner_id: contractor_id}"
                    :show-select-on-focus="false"
                    :sync-action-props="true"
                    :select-button-object="{
                                name: 'CONTRACTOR_CONTRACT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список договорів контрагента',
                                element_title: 'Договір контрагента',
                                show: true,
                                action_props: {owner_id: contractor_id},
                                sync_action_props: true
                              }"
                    @selectChange="get_contractor_balance"
                />
              </div>
            </div>
            <div class="document-header-row px-0 pt-1 pb-3">
              <div style="flex: 0 0 326px; margin-right: 16px">
                <select_input
                    v-model="contractor_chart_of_account_id"
                    :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                    :computed-getter="'get_chart_of_accounts_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    label="Рах розрахунків"
                    :show-select-on-focus="false"
                    :action-filter="'@field=type@;@start-with=S@36@@@;@field=type@;@start-with=S@37'"
                    :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              action_filter: '@field=code@;@start-with=S@36@@@;@field=code@;@start-with=S@37',
                              show: true
                            }"
                    @selectChange="get_contractor_balance"
                />
              </div>
              <div style="flex: 0 0 256px; margin-right: 16px">
                <select_input
                    v-model="advance_chart_of_account_id"
                    :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                    :computed-getter="'get_chart_of_accounts_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    label="Рах. авансу"
                    :show-select-on-focus="false"
                    :action-filter="'@field=type@;@start-with=S@36@@@;@field=type@;@start-with=S@37@@@;@field=type@;@start-with=S@68'"
                    :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              action_filter: '@field=code@;@start-with=S@36@@@;@field=code@;@start-with=S@37@@@;@field=code@;@start-with=S@68',
                              show: true
                            }"
                    @selectChange="get_contractor_balance"
                />
              </div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="grey lighten-4 py-2 px-3 document-info-box">
            <div class="document-info-box-element">
              <div class="info-box-element-title">Підсумки</div>
              <div class="info-box-element-divider"></div>
              <div class="info-box-element-item-wrapper">
                <div class="info-box-element-item">
                  <div class="element-item-title">Всього:</div>
                  <div class="element-item-value">{{ total_sum }} <span>грн.</span></div>
                </div>
                <div class="info-box-element-item">
                  <div class="element-item-title">ПДВ у т.ч:</div>
                  <div class="element-item-value">{{ total_tax }} <span>грн.</span></div>
                </div>
              </div>
            </div>
            <div class="document-info-box-element">
              <div class="info-box-element-title">Стан розрахунків</div>
              <div class="info-box-element-divider"></div>
              <div class="info-box-element-item-wrapper">
                <div class="info-box-element-item">
                  <div class="element-item-title">Сальдо Дт:</div>
                  <div class="element-item-value">{{ contractor_balance_debit }} <span>грн.</span></div>
                </div>
                <div class="info-box-element-item">
                  <div class="element-item-title">Сальдо Кт:</div>
                  <div class="element-item-value">{{ contractor_balance_credit }} <span>грн.</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="document-body pt-0 pb-1" style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
        <div class="px-4 pt-2 pb-0" style="flex: 1; display: flex; height: 100%;">
          <v-row style="height: 100%;">
            <v-col cols="12" sm="12" md="12" class="grey lighten-3 pb-0" style="overflow: hidden; height: 100%;">
              <v-tabs class="custom-tabs without" color="success" style="height: 100%; ">
                <v-tab>
                  Основне
                </v-tab>
                <v-tab>
                  Послуги
                </v-tab>

                <v-tab-item class="pa-0" style="height: 100%;">
                  <div style="width: 100%; height: 97%; background-color: white" class="py-1 px-3 mt-2">
                    <div class="d-flex">
                      <div style="flex: 0 0 430px">
                        <div style="width: 400px; margin-top: 12px;">
                          <select_input
                              v-model="table_chart_of_account_id"
                              :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                              :computed-getter="'get_chart_of_accounts_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="0"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              label="Рахунок доходу"
                              :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              show: true,
                              clearable: true
                            }"
                              :required="true"
                              @selectChange="afterHeaderPayChartAccountChange"
                              :clearable="true"
                          />
                        </div>
                        <div style="width: 400px; margin-top: 12px;" v-if="table_account_detail_1_type">
                          <select_input
                              v-model="table_account_detail_1_value"
                              :computed-action="subconto_type_select_setting[table_account_detail_1_type].computed_action"
                              :computed-getter="subconto_type_select_setting[table_account_detail_1_type].computed_getter"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="0"
                              :show-select-on-focus="false"
                              label="Субконто 1"
                              :required="true"
                              :clearable="true"
                              :select-button-object="{
                                name: subconto_type_select_setting[table_account_detail_1_type].select_button_object.name,
                                group: subconto_type_select_setting[table_account_detail_1_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[table_account_detail_1_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[table_account_detail_1_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[table_account_detail_1_type].select_button_object.title,
                                element_title: subconto_type_select_setting[table_account_detail_1_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                              @selectChange="afterHeaderDetail1Change"
                          />
                        </div>
                        <div style="width: 400px; margin-top: 12px;" v-if="table_account_detail_2_type">
                          <select_input
                              v-model="table_account_detail_2_value"
                              :computed-action="subconto_type_select_setting[table_account_detail_2_type].computed_action"
                              :computed-getter="subconto_type_select_setting[table_account_detail_2_type].computed_getter"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="0"
                              :show-select-on-focus="false"
                              label="Субконто 2"
                              :required="true"
                              :clearable="true"
                              :select-button-object="{
                                name: subconto_type_select_setting[table_account_detail_2_type].select_button_object.name,
                                group: subconto_type_select_setting[table_account_detail_2_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[table_account_detail_2_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[table_account_detail_2_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[table_account_detail_2_type].select_button_object.title,
                                element_title: subconto_type_select_setting[table_account_detail_2_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                              @selectChange="afterHeaderDetail2Change"
                          />
                        </div>
                        <div style="width: 400px; margin-top: 12px;" v-if="table_account_detail_3_type">
                          <select_input
                              v-model="table_account_detail_3_value"
                              :computed-action="subconto_type_select_setting[table_account_detail_3_type].computed_action"
                              :computed-getter="subconto_type_select_setting[table_account_detail_3_type].computed_getter"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="0"
                              :show-select-on-focus="false"
                              label="Субконто 3"
                              :required="true"
                              :clearable="true"
                              :select-button-object="{
                                name: subconto_type_select_setting[table_account_detail_3_type].select_button_object.name,
                                group: subconto_type_select_setting[table_account_detail_3_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[table_account_detail_3_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[table_account_detail_3_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[table_account_detail_3_type].select_button_object.title,
                                element_title: subconto_type_select_setting[table_account_detail_3_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                              @selectChange="afterHeaderDetail3Change"
                          />
                        </div>
                      </div>
                      <div style="flex: 1 1">
                        <div style="width: 400px; margin-top: 12px;">
                          <select_input
                              v-model="tax_chart_of_account_id"
                              :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                              :computed-getter="'get_chart_of_accounts_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="0"
                              label="Бух.рах ПДВ"
                              :show-select-on-focus="false"
                              :action-filter="'@field=type@;@start-with=S@643'"
                              :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              action_filter: '@field=code@;@start-with=S@643',
                              show: true
                            }"
                              @selectChange="onHeaderChange"
                          />
                        </div>
                        <div style="width: 400px; margin-top: 12px;">
                          <select_input
                              v-model="tax_chart_of_account_id_not_confirm"
                              :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                              :computed-getter="'get_chart_of_accounts_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="0"
                              label="Бух.рах ПДВ (непідтвердж.)"
                              :show-select-on-focus="false"
                              :action-filter="'@field=type@;@start-with=S@643'"
                              :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              action_filter: '@field=code@;@start-with=S@643',
                              show: true
                            }"
                              @selectChange="onHeaderChange"
                          />
                        </div>
                        <div style="width: 400px; margin-top: 12px;">
                          <date_input v-model="month" label="Місяць (юр. облік): " :time="false"
                                      :required="false"
                                      :disabled="false"
                                      @userChange="onDateChange" id="date"/>
                        </div>
                        <div style="width: 400px; margin-top: 30px;">
                          <v-checkbox
                              hide-details
                              v-model="without_accounting"
                              color="grey"
                              class="mt-4"
                              disabled
                              label="Не проводити в бух. обліку (юр.облік)"
                          ></v-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
                <v-tab-item class="pa-0" style="height: 100%;">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="document_header_ready"
                           class="success mr-2" :disabled="!document_header_ready"
                           @click.stop="addServiceTableRow"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                    <v-btn small
                           :dark="document_header_ready"
                           class="error lighten-1" :disabled="!document_header_ready"
                           @click.stop="clearDialog"
                    >
                      <v-icon small left>mdi-close</v-icon>
                      Очистити таблицю
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 270px">
                            Номенклатура
                          </div>
                          <div class="document-table-col" style="flex: 0 0 150px">
                            Рахунок доходу
                          </div>
                          <div class="document-table-col" style="flex: 0 0 150px" v-if="show_detail_1">
                            Субконто 1
                          </div>
                          <div class="document-table-col" style="flex: 0 0 150px" v-if="show_detail_2">
                            Субконто 2
                          </div>
                          <div class="document-table-col" style="flex: 0 0 150px" v-if="show_detail_3">
                            Субконто 3
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Кількість
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Ціна
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Сума
                          </div>
                          <div class="document-table-col" style="flex: 0 0 160px" v-if="tax_enable">
                            ПДВ %
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px" v-if="tax_enable">
                            Сума ПДВ
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Всього
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in services_table"
                           :key="`charge-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ services_table.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteServiceTableRow(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 270px">
                          <select_input
                              v-model="charge.nomenclature_id"
                              :computed-action="'SELECT_NOMENCLATURE_LIST_ELEMENTS'"
                              :computed-getter="'get_nomenclature_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              :action-props="{is_service: true}"
                              small
                              transparent
                              :select-button-object="{
                                name: 'NOMENCLATURE_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список номенклатури',
                                element_title: 'Номенклатура',
                                action_props: {is_service: true},
                                show: true
                              }"
                              :required="true"
                              @selectChange="afterNomenclatureSelect"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 150px">
                          <select_input
                              v-model="charge.chart_of_account_id"
                              :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                              :computed-getter="'get_chart_of_accounts_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                // action_filter: '@field=code@;@start-with=S@70@@@;@field=code@;@start-with=S@71',
                                show: true
                              }"
                              :required="true"
                              @selectChange="afterPayChartAccountChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 150px" v-if="show_detail_1">
                          <select_input
                              v-model="charge.account_detail_1_value"
                              :computed-action="subconto_type_select_setting[charge.account_detail_1_type].computed_action"
                              :computed-getter="subconto_type_select_setting[charge.account_detail_1_type].computed_getter"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              small
                              transparent
                              :required="true"
                              :select-button-object="{
                                name: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.element_title,
                                show: true
                              }"/>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 150px" v-if="show_detail_2">
                          <select_input
                              v-if="charge.account_detail_2_type"
                              v-model="charge.account_detail_2_value"
                              :computed-action="subconto_type_select_setting[charge.account_detail_2_type].computed_action"
                              :computed-getter="subconto_type_select_setting[charge.account_detail_2_type].computed_getter"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              small
                              transparent
                              :required="true"
                              :select-button-object="{
                                name: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.element_title,
                                show: true
                              }"/>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 150px" v-if="show_detail_3">
                          <select_input
                              v-if="charge.account_detail_3_type"
                              v-model="charge.account_detail_3_value"
                              :computed-action="subconto_type_select_setting[charge.account_detail_3_type].computed_action"
                              :computed-getter="subconto_type_select_setting[charge.account_detail_3_type].computed_getter"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              small
                              transparent
                              :required="true"
                              :select-button-object="{
                                name: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.element_title,
                                show: true
                              }"/>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.count" v-decimal transparent
                                        small field="count"
                                        :required="false"
                                        @userChange="serviceTableColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.price" v-decimal transparent
                                        small field="price"
                                        :required="false"
                                        @userChange="serviceTableColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.sum" v-decimal transparent
                                        small field="sum"
                                        :required="false"
                                        @userChange="serviceTableColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 160px" v-if="tax_enable">
                          <select_input
                              v-model="charge.tax_id"
                              :computed-action="'SELECT_TAXES_LIST_ELEMENTS'"
                              :computed-getter="'get_taxes_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :action-filter="'@field=type@;@start-with=S@pdv'"
                              small
                              :select-button-object="{
                                name: 'TAXES_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список податків/внесків',
                                element_title: 'Податок/внесок',
                                show: true,
                                action_filter: '@field=tax_group@;@start-with=S@pdv'
                              }"
                              transparent
                              @selectChange="onServiceTableTaxChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px" v-if="tax_enable">
                          <number_input :row_num="charge.row_num" v-model="charge.tax" v-decimal transparent
                                        small field="tax"
                                        :required="false"
                                        @userChange="serviceTableColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.result" v-decimal transparent
                                        small disabled
                                        :required="false"
                          />
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col"
                               :style="`flex: 0 0 ${footer_total_width}px`"
                               style="text-align: right; padding-right: 6px">
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.services_table.count | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.services_table.sum | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 160px; padding-left: 10px" v-if="tax_enable">
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px" v-if="tax_enable">
                            {{ footer_total.services_table.tax | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.services_table.result | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
    <v-card-actions style="flex: 0">
      <template>
        <document_print v-model="show_print"
                        :doc_type="'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME'"
                        :doc_id="itemId"
                        :doc_form="'v1'"
        ></document_print>
        <v-spacer></v-spacer>
        <v-btn depressed color="grey lighten-3" v-if="!isNew" @click="show_print = true">
          <v-icon color="teal">mdi-printer</v-icon>
        </v-btn>
        <v-btn depressed color="grey lighten-3" v-if="!conducted" :loading="getModalLoading"
               :disabled="isNew" @click="doConducted(true)">
          <v-icon color="success">mdi-file-check-outline</v-icon>
        </v-btn>
        <v-btn depressed color="grey lighten-3" v-if="conducted" :loading="getModalLoading"
               :disabled="isNew" @click="doConducted(false)">
          <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
        </v-btn>
        <v-btn depressed text color="secondary darken-1" :loading="getModalLoading"
               @click="crud_item" class="button-accept">
          <v-icon left>mdi-content-save</v-icon>
          Провести та закрити
        </v-btn>
        <v-btn depressed text tile color="grey" @click="closeModal">
          <v-icon left>mdi-close</v-icon>
          Закрити
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS,
  CREATE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS,
  UPDATE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS,
  REMOVE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS,
  CONDUCTED_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS
} from "@/store/actions/accounting/list";
import {mapGetters} from 'vuex'
import {QUESTION_SHOW} from "@/store/actions/question";
import actWorkPerformedOutcomeAPI from "@/utils/axios/accounting/documents/act_work_performed_outcome"
import {maxBy} from "lodash";
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account";
import {format_date_from_python, round_number} from "@/utils/icons";
import {ALERT_SHOW} from "@/store/actions/alert";
import billOutcome from "@/utils/axios/accounting/documents/bill_outcome";
import {subconto_type_select_setting} from '@/utils/accounting'

import ModalAccountingComponentMixin from "@/mixins/modal_account_component";

const modalDeleteId = 'salary_to_pay_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_ActWorkPerformedOutcome",
  mixins: [ModalAccountingComponentMixin],
  components: {
    select_input: () => import("@/components/accounting/input/document_select_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    date_input: () => import("@/components/accounting/input/document_date_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
    document_print: () => import("@/components/DocumentPrintModalWrapper")
  },
  data() {
    return {
      show_print: false,
      subconto_type_select_setting,
      show_detail_1: false,
      show_detail_2: false,
      show_detail_3: false,
      show_tax_dialog: false,
      footer_total: {
        services_table: {count: 0, sum: 0, tax: 0, result: 0},
      },
      table_chart_of_account_id: this.item.table_chart_of_account_id || null,
      table_account_detail_1_type: this.item.table_account_detail_1_type || null,
      table_account_detail_1_value: this.item.table_account_detail_1_value || null,
      table_account_detail_2_type: this.item.table_account_detail_2_type || null,
      table_account_detail_2_value: this.item.table_account_detail_2_value || null,
      table_account_detail_3_type: this.item.table_account_detail_3_type || null,
      table_account_detail_3_value: this.item.table_account_detail_3_value || null,
      tax_in_sum: false,
      tax_in_sum_local: this.item.tax_in_sum || false,
      tax_enable: this.item.tax_enable || null,
      tax_enable_local: this.item.tax_enable || false,
      contractor_chart_of_account_id: this.item.contractor_chart_of_account_id || null,
      advance_chart_of_account_id: this.item.advance_chart_of_account_id || null,
      tax_chart_of_account_id: this.item.tax_chart_of_account_id || null,
      tax_chart_of_account_id_not_confirm: this.item.tax_chart_of_account_id_not_confirm || null,
      price_type: null,
      contractor_id: this.item.contractor_id || null,
      contract_id: this.item.contract_id || null,
      currency_id: this.item.currency_id || null,
      total_sum: 0,
      total_tax: 0,
      contractor_balance_debit: 0,
      contractor_balance_credit: 0,
      services_table: [],
      is_legacy: this.item.is_legacy || false,
      month: this.item.month || null,
      without_accounting: this.item.without_accounting || false,
      flat_id: this.item.flat_id || null,
      house_accounting: this.item.house_accounting || false,
    }
  },
  methods: {
    getDetailsShow() {
      this.show_detail_1 = !!this.services_table.filter(item => item.account_detail_1_type).length
      this.show_detail_2 = !!this.services_table.filter(item => item.account_detail_2_type).length
      this.show_detail_3 = !!this.services_table.filter(item => item.account_detail_3_type).length
    },
    onDateChange() {
      this.get_contractor_balance()
      this.$nextTick(() => {
        this.checkDocument(false, false)
      })
    },
    closeTaxModal(success = false) {
      if (success) {
        this.tax_in_sum = this.tax_in_sum_local
        this.tax_enable = this.tax_enable_local

        this.services_table.forEach(item => {
          this.calcRow(item)
        })
      }


      this.show_tax_dialog = false
    },
    getTotals() {
      this.footer_total.services_table = this.services_table.reduce((acc, item) => {
        acc.count += item.count || 0
        acc.sum += item.sum || 0
        acc.tax += item.tax || 0
        acc.result += item.result || 0
        return acc
      }, {count: 0, sum: 0, tax: 0, result: 0})
      this.total_sum = this.footer_total.services_table.sum || 0
      this.total_tax = this.footer_total.services_table.tax || 0
    },
    getNewRowNum(table) {
      const max_row_item = maxBy(this[table], 'row_num')

      let max_row_num = 1
      if (max_row_item) {
        max_row_num = max_row_item.row_num + 1
      }
      return max_row_num
    },
    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined && item[filter_row.condition] !== 0) {
              skip = item[key] !== null && item[key] !== undefined && item[key] !== 0
            } else {
              skip = true
            }
          } else {
            skip = item[key] !== null && item[key] !== undefined && item[key] !== 0;
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument(show = false, table = true) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'contractor_id': {id: 'contractor_id', text: 'Не заповнений контрагент'},
        'contract_id': {id: 'contract_id', text: 'Не заповнений договір контрагента'},
        'currency_id': {id: 'currency_id', text: 'Не заповнена валюта документа'},
        'contractor_chart_of_account_id':
            {
              id: 'contractor_chart_of_account_id',
              text: 'Не заповнений рахунок розрахунків із контрагентом'
            },
        'tax_chart_of_account_id': {id: 'tax_chart_of_account_id', text: 'Не заповнений рахунок обліку ПДВ '},
      }

      if (this.table_account_detail_1_type) {
        header['table_account_detail_1_value'] = {id: 'table_account_detail_1_value', text: 'Не заповнене субконто 1'}
      }
      if (this.table_account_detail_2_type) {
        header['table_account_detail_2_value'] = {id: 'table_account_detail_2_value', text: 'Не заповнене субконто 1'}
      }
      if (this.table_account_detail_3_type) {
        header['table_account_detail_3_value'] = {id: 'table_account_detail_3_value', text: 'Не заповнене субконто 1'}
      }

      const table_charge = {
        'nomenclature_id': {id: 'nomenclature_id', table: 'Послуги', text: 'Не заповнена номенклатура'},
        'chart_of_account_id': {id: 'chart_of_account_id', table: 'Послуги', text: 'Не заповнений рахунок доходу'},
        'count': {id: 'count', table: 'Послуги', text: 'Не заповнена кількість'},
        'price': {id: 'price', table: 'Послуги', text: 'Не заповнена ціна'},
        'sum': {id: 'sum', table: 'Послуги', text: 'Не заповнена сума'},
        'account_detail_1_value': {
          condition: 'account_detail_1_type',
          text: 'Не заповнене субконто 1'
        },
        'account_detail_2_value': {
          condition: 'account_detail_2_type',
          text: 'Не заповнене субконто 2'
        },
        'account_detail_3_value': {
          condition: 'account_detail_3_type',
          text: 'Не заповнене субконто 3'
        },
      }

      if (this.tax_enable) {
        table_charge.tax_id = {
          table: 'Послуги',
          text: 'Не заповнена ставка ПДВ'
        }
        table_charge.tax = {
          table: 'Послуги',
          text: 'Не заповнена сума ПДВ'
        }
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        this.checkDocumentTable(table_charge, 'Послуги', 'services_table')
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },
    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, {text, color})
            }

          })
    },

    clearPersonData(payload) {
      let local_data = payload || {}

      this.tab = 0
      this.conducted = local_data.conducted || false
      this.date = local_data.date || null
      this.number = local_data.number || null
      this.currency_id = local_data.currency_id || null
      this.contractor_id = local_data.contractor_id || null
      this.contract_id = local_data.contract_id || null
      this.tax_enable = local_data.tax_enable || false
      this.tax_in_sum = local_data.tax_in_sum || false
      this.contractor_chart_of_account_id = local_data.contractor_chart_of_account_id || null
      this.advance_chart_of_account_id = local_data.advance_chart_of_account_id || null
      this.tax_chart_of_account_id = local_data.tax_chart_of_account_id || null
      this.tax_chart_of_account_id_not_confirm = local_data.tax_chart_of_account_id_not_confirm || null
      this.table_chart_of_account_id = local_data.table_chart_of_account_id || null
      this.table_account_detail_1_type = local_data.table_account_detail_1_type || null
      this.table_account_detail_1_value = local_data.table_account_detail_1_value || null
      this.table_account_detail_2_type = local_data.table_account_detail_2_type || null
      this.table_account_detail_2_value = local_data.table_account_detail_2_value || null
      this.table_account_detail_3_type = local_data.table_account_detail_3_type || null
      this.table_account_detail_3_value = local_data.table_account_detail_3_value || null
      this.itemId = payload.id || null
      this.is_legacy = local_data.is_legacy || false
      this.month = local_data.month || null
      this.without_accounting = local_data.without_accounting || false
      this.flat_id = local_data.flat_id || null
      this.house_accounting = local_data.house_accounting || false

      this.services_table = []
    },
    clearDocument() {
      this.services_table = []
      this.getTotals()
    },
    get_account_details(chart_id) {
      const chart = this.charts.find(c => c.id === chart_id) || {}
      const details = {
        account_detail_1: {id: null, type: null},
        account_detail_2: {id: null, type: null},
        account_detail_3: {id: null, type: null},
      }
      if (chart.id) {
        const ch_details = chart.details || []

        const obj_1 = ch_details.find(i => i.order === 1)
        const obj_2 = ch_details.find(i => i.order === 2)
        const obj_3 = ch_details.find(i => i.order === 3)

        if (obj_1) {
          details.account_detail_1.type = obj_1.detail_type
          details.account_detail_1.id = obj_1.id
        }
        if (obj_2) {
          details.account_detail_2.type = obj_2.detail_type
          details.account_detail_2.id = obj_2.id
        }
        if (obj_3) {
          details.account_detail_3.type = obj_3.detail_type
          details.account_detail_3.id = obj_3.id
        }
      }
      return details
    },

    onHeaderChange() {
      this.$nextTick(() => {
        this.checkDocument(false, false)
      })
    },

    afterPayChartAccountChange(payload) {
      const row = this.services_table.find(item => item.row_num === payload.row_num)
      if (row) {
        let details = {}
        if ('text' in payload && 'value' in payload) {
          details = this.get_account_details(payload.value)
        } else {
          details = this.get_account_details(payload.id)
        }
        row.account_detail_1_type = details.account_detail_1.type
        row.account_detail_1_value = null
        row.account_detail_2_type = details.account_detail_2.type
        row.account_detail_2_value = null
        row.account_detail_3_type = details.account_detail_3.type
        row.account_detail_3_value = null
      }
      this.getDetailsShow()
      this.$nextTick(() => {
        this.checkDocument(false)
      })
    },
    afterHeaderPayChartAccountChange(payload) {
      let details = {}
      if ('text' in payload && 'value' in payload) {
        details = this.get_account_details(payload.value)
      } else {
        details = this.get_account_details(payload.id)
      }
      this.table_account_detail_1_type = details.account_detail_1.type
      this.table_account_detail_1_value = null
      this.table_account_detail_2_type = details.account_detail_2.type
      this.table_account_detail_2_value = null
      this.table_account_detail_3_type = details.account_detail_3.type
      this.table_account_detail_3_value = null

      this.services_table.forEach(item => {
        item.account_detail_1_type = this.table_account_detail_1_type
        item.account_detail_2_type = this.table_account_detail_2_type
        item.account_detail_3_type = this.table_account_detail_3_type
        item.account_detail_1_value = this.table_account_detail_1_value
        item.account_detail_2_value = this.table_account_detail_2_value
        item.account_detail_3_value = this.table_account_detail_3_value
      })
      this.$nextTick(() => {
        this.checkDocument(false)
      })
    },
    afterHeaderDetail1Change(payload) {
      let value = null
      if ('text' in payload && 'value' in payload) {
        value = payload.value
      } else {
        value = payload.id
      }

      this.services_table.forEach(item => {
        item.account_detail_1_type = this.table_account_detail_1_type
        item.account_detail_1_value = value
      })
      this.$nextTick(() => {
        this.checkDocument(false)
      })
    },
    afterHeaderDetail2Change(payload) {
      let value = null
      if ('text' in payload && 'value' in payload) {
        value = payload.value
      } else {
        value = payload.id
      }

      this.services_table.forEach(item => {
        item.account_detail_2_type = this.table_account_detail_2_type
        item.account_detail_2_value = value
      })
      this.$nextTick(() => {
        this.checkDocument(false)
      })
    },
    afterHeaderDetail3Change(payload) {
      let value = null
      if ('text' in payload && 'value' in payload) {
        value = payload.value
      } else {
        value = payload.id
      }

      this.services_table.forEach(item => {
        item.account_detail_3_type = this.table_account_detail_3_type
        item.account_detail_3_value = value
      })
      this.$nextTick(() => {
        this.checkDocument(false)
      })
    },

    addServiceTableRow() {
      const charge_703_id = '703'
      const chart_703 = this.charts.find(c => c.code === charge_703_id) || {}
      const tax_20 = this.settings?.pdv_20?.value || null
      const tax_id = this.settings?.default_pdv_percent?.value || null

      let details = {}

      if (!this.table_chart_of_account_id) {
        details = this.get_account_details(chart_703.id)
      }

      this.services_table.push(
          {
            nomenclature_id: null,
            count: 1,
            price: 0,
            sum: 0,
            tax: 0,
            result: 0,
            tax_id: tax_id ? tax_id : tax_20,
            chart_of_account_id: this.table_chart_of_account_id
                ? this.table_chart_of_account_id
                : chart_703.id,
            account_detail_1_value: this.table_account_detail_1_value,
            account_detail_1_type: this.table_chart_of_account_id
                ? this.table_account_detail_1_type
                : details.account_detail_1.type,
            account_detail_2_value: this.table_account_detail_2_value,
            account_detail_2_type: this.table_chart_of_account_id
                ? this.table_account_detail_2_type
                : details.account_detail_2.type,
            account_detail_3_value: this.table_account_detail_3_value,
            account_detail_3_type: this.table_chart_of_account_id
                ? this.table_account_detail_3_type
                : details.account_detail_3.type,
            row_num: this.getNewRowNum('services_table'),
          }
      )
      this.getDetailsShow()
    },
    getTaxTariff(tax_id) {
      if (!tax_id) return null
      const tariffs = {}
      const pdv_without = this.settings?.pdv_without?.value || 0
      const pdv_0 = this.settings?.pdv_0?.value || 0
      const pdv_5 = this.settings?.pdv_5?.value || 0
      const pdv_7 = this.settings?.pdv_7?.value || 0
      const pdv_9 = this.settings?.pdv_9?.value || 0
      const pdv_20 = this.settings?.pdv_20?.value || 0

      tariffs[pdv_without] = null
      tariffs[pdv_0] = 0
      tariffs[pdv_5] = 5
      tariffs[pdv_7] = 7
      tariffs[pdv_9] = 9
      tariffs[pdv_20] = 20

      return tariffs[tax_id] || null
    },
    deleteServiceTableRow(payload) {
      this.services_table.splice(this.services_table.indexOf(payload), 1)
      this.getTotals()
    },
    serviceTableColChange(payload) {
      this.$nextTick(() => {
        const row = this.services_table.find(i => i.row_num === payload.row_num)
        if (!row) return

        this.calcRow(row, payload.field)
      })
    },
    calcRow(payload, col = '') {
      if (!payload) return
      const tax_tariff = this.getTaxTariff(payload.tax_id)

      if (col === 'sum') {
        payload.price = round_number(payload.sum / payload.count, 4)
      }
      if (this.tax_enable) {
        if (col === 'tax') {
          if (this.tax_in_sum) {
            payload.tax = this.round_number(payload.sum / (100 / tax_tariff + 1), 2)
            payload.result = payload.sum
          } else {
            payload.tax = this.round_number(payload.sum * tax_tariff / 100, 2)
            payload.result = this.round_number(payload.sum + payload.tax, 2)
          }
          return;
        }
      } else {
        payload.tax = 0
        payload.result = payload.sum
      }

      payload.sum = round_number(payload.count * payload.price, 2)

      if (this.tax_enable) {
        if (this.tax_in_sum) {
          payload.tax = this.round_number(payload.sum / (100 / tax_tariff + 1), 2)
          payload.result = payload.sum
        } else {
          payload.tax = this.round_number(payload.sum * tax_tariff / 100, 2)
          payload.result = this.round_number(payload.sum + payload.tax, 2)
        }
      } else {
        payload.tax = 0
        payload.result = payload.sum
      }

      this.getTotals()
    },
    onServiceTableTaxChange(payload) {
      this.$nextTick(() => {
        const row = this.services_table.find(i => i.row_num === payload.row_num)
        if (!row) return
        this.calcRow(row, 'tax_id')
      })
    },

    afterNomenclatureSelect(payload) {
      const val = payload || {}
      let pdv, is_service
      if ("value" in val) {
        pdv = +(val.type || '0')
        is_service = val.additional_field_1 === "True"
      } else {
        pdv = val.tax_type
        is_service = val.is_service
      }

      if (val.row_num) {
        const row = this.services_table.find(item => item.row_num === val.row_num)
        if (row) {
          if (!is_service) {
            row.nomenclature_id = null
            return;
          }
          row.tax_id = pdv
          this.serviceTableColChange(val)
        }
      }
    },

    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = this.get_payload_for_crud()

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        this.$store.dispatch(UPDATE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    get_payload_for_crud() {
      const payload = {
        date: this.date,
        currency_id: this.currency_id,
        contractor_id: this.contractor_id,
        contract_id: this.contract_id,
        tax_in_sum: this.tax_in_sum || false,
        tax_enable: this.tax_enable || false,
        contractor_chart_of_account_id: this.contractor_chart_of_account_id,
        advance_chart_of_account_id: this.advance_chart_of_account_id,
        tax_chart_of_account_id: this.tax_chart_of_account_id,
        tax_chart_of_account_id_not_confirm: this.tax_chart_of_account_id_not_confirm,
        table_chart_of_account_id: this.table_chart_of_account_id,
        table_account_detail_1_type: this.table_account_detail_1_type,
        table_account_detail_2_type: this.table_account_detail_2_type,
        table_account_detail_3_type: this.table_account_detail_3_type,
        table_account_detail_1_value: this.table_account_detail_1_value,
        table_account_detail_2_value: this.table_account_detail_2_value,
        table_account_detail_3_value: this.table_account_detail_3_value,
        month: this.month,
        is_legacy: this.is_legacy,
        without_accounting: this.without_accounting,
        table: this.services_table,
        flat_id: this.flat_id,
        house_accounting: this.house_accounting
      }

      if (!this.isNew) {
        payload.id = this.itemId
      }

      return payload
    },
    load_select_data() {
      return Promise.all([
        this.$store.dispatch(SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS),
      ])
    },
    fetch_data_by_id() {
      if (this.isNew) {
        chartOfAccountAPI.get_all()
            .then(response => response.data)
            .then(data => {
              this.charts = data
            })
            .finally(() => {
              if (this.item.basic_create_id) {
                if (this.item.basic_create_type === 'DOCUMENT_BILL_OUTCOME') {
                  billOutcome.get_document_by_id(this.item.basic_create_id)
                      .then(response => response.data)
                      .then(data => {
                        const charge_703_id = '703'
                        const chart_703 = this.charts.find(c => c.code === charge_703_id) || {}

                        let details = {}

                        if (!this.table_chart_of_account_id) {
                          details = this.get_account_details(chart_703.id)
                        }

                        this.contractor_id = data.contractor_id
                        this.contract_id = data.contract_id

                        data.services.forEach(row => {
                          this.services_table.push(
                              {
                                nomenclature_id: row.nomenclature_id,
                                tax_id: row.tax_id,
                                count: row.count,
                                price: row.price,
                                sum: row.sum,
                                result: row.result,
                                row_num: this.getNewRowNum('services_table'),
                                chart_of_account_id: this.table_chart_of_account_id
                                    ? this.table_chart_of_account_id
                                    : chart_703.id,
                                account_detail_1_value: this.table_account_detail_1_value,
                                account_detail_1_type: this.table_chart_of_account_id
                                    ? this.table_account_detail_1_type
                                    : details.account_detail_1.type,
                                account_detail_2_value: this.table_account_detail_2_value,
                                account_detail_2_type: this.table_chart_of_account_id
                                    ? this.table_account_detail_2_type
                                    : details.account_detail_2.type,
                                account_detail_3_value: this.table_account_detail_3_value,
                                account_detail_3_type: this.table_chart_of_account_id
                                    ? this.table_account_detail_3_type
                                    : details.account_detail_3.type,
                              }
                          )
                        })
                        this.getTotals()
                        this.checkDocument(false, true)
                        this.getDetailsShow()
                      })
                }
              }
            })
        return
      } else {
        chartOfAccountAPI.get_all()
            .then(response => response.data)
            .then(data => {
              this.charts = data
            })
      }
      this.$nextTick(() => {
        this.checkDocument(false, true)
      })

      actWorkPerformedOutcomeAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.tab = 0
            this.date = data.date
            this.number = data.number
            this.conducted = data.conducted
            this.currency_id = data.currency_id
            this.contractor_id = data.contractor_id
            this.contract_id = data.contract_id
            this.tax_in_sum = data.tax_in_sum
            this.tax_enable = data.tax_enable
            this.tax_enable_local = data.tax_enable
            this.contractor_chart_of_account_id = data.contractor_chart_of_account_id
            this.advance_chart_of_account_id = data.advance_chart_of_account_id
            this.tax_chart_of_account_id = data.tax_chart_of_account_id
            this.tax_chart_of_account_id_not_confirm = data.tax_chart_of_account_id_not_confirm
            this.table_chart_of_account_id = data.table_chart_of_account_id
            this.table_account_detail_1_type = data.table_account_detail_1_type
            this.table_account_detail_1_value = data.table_account_detail_1_value
            this.table_account_detail_2_type = data.table_account_detail_2_type
            this.table_account_detail_2_value = data.table_account_detail_2_value
            this.table_account_detail_3_type = data.table_account_detail_3_type
            this.table_account_detail_3_value = data.table_account_detail_3_value
            this.is_legacy = data.is_legacy || false
            this.month = data.month || null
            this.without_accounting = data.without_accounting || false
            this.flat_id = data.flat_id || null
            this.house_accounting = data.house_accounting || false

            this.services_table = data.table.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })

            this.getTotals()
            this.get_contractor_balance()
            this.getDetailsShow()
          })
    },
    get_contractor_balance() {
      this.$nextTick(() => {
        this.checkDocument(false, false)
      })

      if (!this.date || !this.contract_id || !this.contract_id || !this.contractor_chart_of_account_id) {
        this.contractor_balance_debit = 0
        this.contractor_balance_credit = 0
        return
      }

      actWorkPerformedOutcomeAPI.get_contractor_balance(
          {
            balance_date: this.date,
            chart_id: this.contractor_chart_of_account_id,
            detail_id_value_first: this.contractor_id,
            detail_id_value_second: this.contract_id,
            detail_id_value_third: null
          }
      )
          .then(response => response.data)
          .then(data => {
            this.contractor_balance_debit = data.debit
            this.contractor_balance_credit = data.credit
          })
          .catch(err => {
                this.$store.commit(ALERT_SHOW, {text: err.response.data.detail, color: 'error lighten-1'})
              }
          )
    },

    closeModal() {
      this.$emit('closeModal')
      this.clearPersonData(this.item)

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення акту виконаних робіт (вихідний) № ${this.number} від ${format_date_from_python(this.date)}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    clearDialog() {
      const payload = {
        text: `Очистити таблицю з послугами?`,
        accept_button: true,
        id: 'clear_service_table'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.load_select_data()
              this.clearPersonData(payload)
              this.fetch_data_by_id()
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher_item) {
                  this.watcher_item()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS,
                      {delete_id: this.itemId, flat_id: this.flat_id}
                  )
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
              if (payload.id === 'clear_service_table') {
                if (payload.answer) {
                  this.clearDocument()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      settings: 'getAccountingGeneralSettings',
    }),
    footer_total_width() {
      if (this.show_detail_1 && this.show_detail_2 && this.show_detail_3) {
        return 940
      }
      if (this.show_detail_1 && this.show_detail_2 && !this.show_detail_3) {
        return 790
      }
      if (this.show_detail_1 && !this.show_detail_2 && !this.show_detail_3) {
        return 640
      }
      return 490
    },
    document_header_ready() {
      return this.document_state.header_ready
    }
  },
}
</script>
